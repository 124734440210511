<template>
	<el-card class="box-card dd-card" :class="{disabled: reactiveDisabled}">
		<div class="header" @click="toggleDropDown">
			<div class="card-title" v-if="$slots.title">
				<slot name="title">{{ title }}</slot>
			</div>
			<div class="card-title" v-html="title" v-else></div>
			<i class="el-icon-arrow-down dropdown-arrow" :class="{'rotate180': isCollapsed}"></i>
		</div>
		<div class="body" v-show="isCollapsed && !inactive">
			<slot />
		</div>
	</el-card>
</template>

<script>
	export default {
		name: 'DDcard',
		props: ['title', 'collapsed', 'disabled', 'inactive'],
		data() {
			return {
				isCollapsed: typeof this.collapsed !== 'undefined' ? this.collapsed : true
			};
		},
		methods: {
			toggleDropDown() {
				if (this.reactiveDisabled || this.reactiveInactive) return false;
				this.isCollapsed = !this.isCollapsed;
				this.$emit('toggledCard', this.isCollapsed);
			}
		},
		computed: {
			reactiveDisabled() {
				return this.disabled;
			},
			reactiveInactive() {
				if (this.inactive) this.isCollapsed = false
				return this.inactive;
			},
			hasTitleSlot() {
				return !!this.$slots['title'] || !!this.$scopedSlots['title'];
			}
		},
		watch: {
			collapsed(val) {
				this.isCollapsed = val;
			}
		},
		created() {}
	};
</script>

<style lang="scss">
	@import './src/styles/modules/ddcard.scss'
</style>